import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { getMatDialogConfig, getSnackBarConfig } from 'src/app/utils/material.utils';
import { DialogNames, LazyDialogService } from 'src/app/services/lazy-dialog.service';
import { ConfirmationModalComponent } from '../confirmation-modal';
import { Router } from '@angular/router';
import { ContactType, FieldType } from 'src/app/services/hobbyts.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StorageService } from 'src/app/services/storage.service';

interface ContactNumberType extends ContactType {
  showNumber: boolean;
}

@Component({
  selector: 'hb-field-modal',
  templateUrl: './field-modal.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./field-modal.component.scss'],
  imports: [MatIconModule, MatFormFieldModule, ClipboardModule]
})
export class FieldModalComponent implements OnInit {
  public field: FieldType;
  public fieldContacts: ContactNumberType[];
  public loggedInUserId: string | null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: FieldType,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<FieldModalComponent>,
    private lazyDialog: LazyDialogService,
    private router: Router,
    private snackBar: MatSnackBar,
    private storageService: StorageService
  ) {}

  public ngOnInit(): void {
    this.loggedInUserId = this.storageService.getValue('store-userId');
    this.field = this.data;
    if (this.field.contacts) {
      this.fieldContacts = this.field.contacts.map((fieldContact: ContactType) => ({
        label: fieldContact.label,
        contact: fieldContact.contact,
        showNumber: false
      }));
    }
  }

  public onClose(): void {
    this.dialogRef.close();
  }

  public async toggleShowNumber(contact: ContactNumberType): Promise<void> {
    if (this.loggedInUserId) {
      contact.showNumber = !contact.showNumber;
    } else {
      const confirmationMsg = $localize`You should log in to see phone numbers`;
      const config = getMatDialogConfig(this.dialog, ConfirmationModalComponent, { confirmationMsg });

      const ref = await this.lazyDialog.openLazyLoadedDialog<ConfirmationModalComponent>(DialogNames.CONFIRM, config);
      ref.afterClosed().subscribe((isConfirmed) => {
        if (isConfirmed) {
          this.dialogRef.close();
          void this.router.navigate(['login']);
        }
      });
    }
  }

  public isPhoneNumber(value: string): boolean {
    const phoneNumberRegex = /^[+]?[\d()\- ]{10,}$/;
    return phoneNumberRegex.test(value);
  }

  public showCopiedTooltip(phoneNum: string): void {
    this.snackBar.open($localize`phone number ${phoneNum} copied`, $localize`Close`, getSnackBarConfig());
  }
}
